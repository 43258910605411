<template>
  <div class="body" v-loading="pageLoading">
    <div class="banxin" style="position:relative">
      <div style="background: #fff;">
        <div class="top">
          <div class="df aic">
            <div style="margin-right: 50px">
              <img src="@/assets/images/cart/dui.png" alt="" />
            </div>
          <div>
          <div class="tt">订单提交成功,请尽快支付</div>
            <div class="time">
              本次支付{{ payInfo.orderNum }}笔订单，请在剩余付款时间
              <span style="color:#FCE300">{{ payEndTime }}</span>
              内完成支付，超时将取消订单
            </div>
          </div>
        </div>
        <!-- <div class="notice" @click="ruledialog = true">
            <img src="@/assets/images/cart/pay-notice.svg" alt="">
            <span>规则说明</span>
        </div> -->
      </div>
      <!-- 药采贷申请-->
      <div v-if="showLoanApply" class="loan-apply">
        <div class="loan-apply-title">
          <img src="@/assets/images/cart/dai.png" alt="">
          <span>药采贷</span>
        </div>
        <div>
          <span>开通药采贷，解决您资金周转难题</span>
          <span class="button" @click="applyLoan">申请</span>
        </div>
      </div>
  
      <div class="bottom">
        <!-- 慧金豆-->
        <div class="list">
          <div class="notice-box">
            <img src="@/assets/images/cart/pay-notice-icon.png" alt="">
            <span>提示：可点击 <span style="color: #5FC08C">“充值”</span> 来减少后续支付次数；如充值额度不够请联系客服处理。</span>
          </div>
          <div class="info-box">
            <div class="box">
              <el-checkbox class="box-select" v-model="currentHuiIcon.mainSelect" :disabled="currentHuiIcon.surplusAmount===0 || payInfo.status===100" @change="changeIconAmount"></el-checkbox>
              <img class="box-icon" src="@/assets/images/huijindou.png" alt="" />
              <span class="fs18 box-name">慧金豆</span>
              <div class="feature-box feature-box-green" @click="gopreferential">充值</div>
            </div>
            <div class="residue">可用余额：{{ formatMoney(currentHuiIcon.surplusAmount,'￥') }}</div>
            <div class="right">
              <div class="dikou">
                需支付：<span class="bigred">{{ formatMoney(currentHuiIcon.payAmount,'￥')}}</span>
              </div>
            </div>
          </div>
        </div>

        <!-- 药采贷支付-->
        <div v-if="!showLoanApply" class="list">
          <div class="info-box">
            <el-radio v-model="currentPayType" :label="100" :disabled="payInfo.status===100" class="info-radio" @input="changeCurrentType">
              <div class="box" style="padding-left: 10px">
                <img class="box-icon" src="@/assets/images/pay/loan.png" alt="" />
                <span class="fs18 box-name">药采贷支付</span>
              </div>
            </el-radio>
            <div class="residue"></div>
            <div  v-if="currentPayType === 100" class="right">
              <div class="dikou">
                需支付：<span class="bigred">{{ formatMoney(currentPayAmonut,'￥')}}</span>
              </div>
            </div>
          </div>
          <div v-if="currentPayType === 100" class="scroll-box">
            <div class="box-wrapper">
              <div class="pay-item-box">
                <div class="pay-item loan-item" :class="{'checked': currentLoanPayType === item.loansType, 'forbid': item.forbid}" v-for="(item,index) in loanList" :key="index" @click="changeLoanType(item)">
                  <img v-show="currentLoanPayType === item.loansType" class="img-check" src="../../assets/images/cart/item-check.png">
                  <img :src="item.img" alt="">
                  <span>{{ item.name }}</span>
                  <div class="remark">{{ item.remark }}</div>
                  <div class="limit">可用余额：{{ formatMoney(item.surplusAmount, '￥') }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 现金支付-->
        <div class="list" v-for="(item,index) in cashList" :key="index">
          <div class="info-box">
            <el-radio v-model="currentPayType" :label="item.cashType" class="info-radio" :disabled="item.forbid" @input="changeCurrentType">
              <div class="box" style="padding-left: 10px">
                <img class="box-icon" :src="item.img" alt="" />
                <span class="fs18 box-name">{{ item.name }}</span>
              </div>
            </el-radio>
            <div v-if="item.cashType===140" class="residue">本月可用余额：{{ formatMoney(item.surplusAmount, '￥') }}</div>
            <div  v-if="currentPayType === item.cashType" class="right">
              <div class="dikou">
                需支付：<span class="bigred">{{ formatMoney(currentPayAmonut,'￥')}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div class="footer">
        <div style="color: #1a1a1a">
          <span>应付：<span class="bigred">{{ formatMoney(currentPayAmonut,'￥')}}</span></span>
          <!-- <span class="info">已付：{{ formatMoney(payInfo.amountPaid,'￥')}}</span> -->
          <span class="info">总额：{{ formatMoney(payInfo.totalAmount,'￥')}}</span>
        </div>
        <div class="footer-operation">
          <el-button @click="$router.push('/shoppingCart')">返回购物车</el-button>
          <el-button :loading="doubleclick" :disabled="payInfo.willPayAmount != payInfo.totalAmount" @click="throttlePay" type="success">去支付</el-button>
        </div>
      </div>
    </div>
  </div>

  <!-- 微信支付弹框 -->
  <div class="paylog">
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="buy_code"
      width="430px"
      @close="buy_close"
      v-loading="buyLoading"
    >
      <img src="@/assets/images/quicklyOrder/payIMG.png" alt="" />
      <div
        style="display: flex;justify-content: center;position: absolute;top: 0;right: 0;transform: translate(-70px, 165px);"
        class="buy_box dis_f_c_c"
      >
        <div id="qrcode" ref="qrcode" class="img1 dis_f_c_c"></div>
        <img src="static/image/store/pay2.png" class="img2" alt="" />
      </div>
      <div @click="closeQrcode" style="margin-top: 40px" class="cup flex-center">
        <img src="@/assets/images/quicklyOrder/Close.png" alt="" />
      </div>
    </el-dialog>
  </div>
  
  <!-- 慧金豆抵扣规则 -->
  <div class="rule-box">
    <el-dialog title="慧金豆抵扣规则" :visible.sync="ruledialog" width="30%">
      <p class="rule-tip">
        支付订单时：
      </p>
      <p class="rule-txt">
        1.只有“药采贷专区”的商品可以自动使用慧金豆抵扣。
      </p>
      <p class="rule-txt">
        2.“药采贷专区”的商品可能和其他专区的商品重合，该部分商品也 可以自动使用慧金豆抵扣。
      </p>
      <p class="rule-txt">
        3.如当前剩余的慧金豆不足以抵扣全部的“药采贷专区”商品，则剩
        余金额可继续选择现金或账期进行支付（账期需要专门申请），或者 可通过”充值“来增加慧金豆余额。
      </p>
      <p class="rule-txt">
        4.其他专区的商品（未显示在”药采贷专区“）仅能够使用现金或账 期支付。
      </p>
    </el-dialog>
  </div>
  
  <!--支付密码 -->
  <el-dialog title="输入支付密码" class="paydialog" :visible.sync="payDialog" width="400px" :close-on-click-modal="false" @closed="cancelPay">
    <div class="df mb8 aic">
      <span class="nowarp"> 请输入支付密码：</span>
      <el-input placeholder="请输入支付密码" v-model="password" show-password></el-input>
    </div>

    <div class="tip flex-end">
      无支付密码/忘记密码
      <span @click="goresetPassword" class="gr cup">&nbsp;请重置密码</span>
    </div>

    <span class="dialog-footer flex-end">
      <el-button @click="cancelPay">取消</el-button>
      <el-button type="primary" @click="throttlePay">确定</el-button>
    </span>
  </el-dialog>
  
  <LoanApply :dialog-visible="dialogVisible" @close="closeLoan"/>
</div>
</template>
  
<script>
  import Decimal from "decimal.js" 
  import LoanApply from "../loanApply.vue";
  //法人慧金豆余额
  import { findOrderByPaySn, getPaysnByOrderIds, getPayInfoBySn, submitPayInfoAPI,cancelPayAPI } from "@/request/Orders/order";
  import {formatMoney, getLabelByValue} from '@/utils/tool'
  import Base64 from '@/utils/base64'
  import axios from "axios";
  import QRCode from "qrcodejs2"; // 引入qrcode,转二维码
  import _ from 'lodash';
  export default {
    components: {
      LoanApply
    },
    watch: {
      $route: {
        immediate: true,
        handler(newValue, oldValue) {
          if (newValue.query) {
            if (typeof newValue.query.checkPay == "string") {
              this.ids = [];
              this.ids.push(newValue.query.checkPay);
            } else {
              this.ids = newValue.query.checkPay;
            }
          }
        },
      },
      buy_code: {
        immediate: true,
        handler(newValue, oldValue) {
          console.log(newValue);
          if (!this.buy_code) {
            this.doubleclick = false;
          }
        },
      },
    },
    data() {
      return {
        formatMoney,
        getLabelByValue,
        doubleclick: false,
        password: "",
        
        ruledialog: false,
        ids: [],
        //gps
        location: "",
        buy_code: false,

        qrcode: "", // 二维码
        buyLoading: false,
        payDialog: false,
        pageLoading: true,
        dialogVisible: false,

        paySn: 0,
        payInfo:{},  // 支付单相关信息
        payEndTime:'', // 支付结束时间
        payTimer:null, // 支付倒计时计时器
        selectPng: require('../../assets/images/cart/select.png'), // 未选中图片
        selectedPng: require('../../assets/images/cart/bigcheck.png'),  // 选中图片
        selectForbidPng: require('../../assets/images/cart/select-forbid.png'), // 禁用图片
        currentHuiIcon:{},  // 当前慧金豆信息
        cashList:[
          {name:'微信', img:require('../../assets/images/cart/weixin.png'), cashType: 141, payAmount:0, surplusAmount:0, status:0, select: false, forbid: false},
          {name:'支付宝', img:require('../../assets/images/cart/zhifubao.png'), cashType: 142, payAmount:0, surplusAmount:0, status:0, select: false, forbid: false},
          {name:'账期', img:require('../../assets/images/cart/zhangqi.png'), cashType: 140, payAmount:0, surplusAmount:0, status:0, select: false, forbid: false},
        ],
        loanList:[{name:'宜宾市商业银行-个人', img:require('../../assets/images/bank/11.png'), loansType: 110, payAmount:0,surplusAmount:0, remark:'', select: true}],
        currentLoan:{},  // 当前选中授信产品
        statusList:[
          {label:'待支付', value: 0},
          {label:'支付中', value: 100},
          {label:'支付成功', value: 120},
          {label:'支付失败', value: 140}
        ],
        bankList:[
          {label:'宜宾市商业银行-个人', value: 110},
          {label:'宜宾市商业银行-企业', value: 111},
          {label:'天府银行-个人', value: 120},
          {label:'天府银行-企业', value: 121},
        ],
        timer1: null,
        showLoanApply: false,

        currentPayType: null,  // 当前选中支付方式
        currentLoanPayType: null, // 药采贷支付方式
        currentPayAmonut: 0, // 所需支付金额
      };
    },
    mounted() {
      axios({
        url: `https://restapi.amap.com/v3/ip?key=d369aeea0b01d0c9a5c4b0fac64127ad`,
      })
        .then((res) => {
          if (res.status == 200) {
            const loc = res.data.rectangle.split(";")[0].split(",");
            this.location = (loc && loc[1] ? loc[1] : "30.53006918") + "-" + (loc && loc[0] ? loc[0] : "103.9017713");
          }
        })
        .catch((error) => {});
    },
    created() {
      this.batchOrder();
    },
    methods: {
      gopreferential() {
        window.open(`/user/preferential`);
      },
      goresetPassword() {
        window.open(`/user/resetPassword`);
      },
  
      showtime(payTime) {
        var nowtime = new Date(), //获取当前时间
          endtime = new Date(payTime); //定义结束时间
        var lefttime = endtime.getTime() - nowtime.getTime(), //距离结束时间的毫秒数
          leftd = Math.floor(lefttime / (1000 * 60 * 60 * 24)), //计算天数
          lefth = Math.floor((lefttime / (1000 * 60 * 60)) % 24), //计算小时数
          leftm = Math.floor((lefttime / (1000 * 60)) % 60), //计算分钟数
          lefts = Math.floor((lefttime / 1000) % 60); //计算秒数
        if (leftd > -1 && lefth > -1) {
          if (leftd >= 1) {
            return leftd + "天" + lefth + "时" + leftm + "分" + lefts + "秒"; // 返回倒计时的字符串
          } else if (leftd <= 0 && lefth >= 1) {
            return lefth + "时" + leftm + "分" + lefts + "秒"; // 返回倒计时的字符串
          } else {
            return leftm + "分" + lefts + "秒"; // 返回倒计时的字符串
          }
        }
      },
      daojishi() {
        this.payEndTime = this.showtime(this.payInfo.payTime);
        this.$forceUpdate();
      },
      
      
      // 根据ids获取paySn
      batchOrder() {
        getPaysnByOrderIds(this.ids).then((res) => {
          if (res.code === 200) {
            this.paySn = res.data
            this.getPayInfo(res.data)
          }
        })
      },
      // 根据paySn获取支付信息
      getPayInfo(sn) {
        getPayInfoBySn(sn).then(res => {
          if (res.code === 200) {
            this.payInfo = res.data;
            this.currentHuiIcon = res.data.amountResDTO;
            if (this.currentHuiIcon.payAmount > 0) {
              this.currentHuiIcon.mainSelect = true
            } else {
              this.currentHuiIcon.status = null
            }

            this.payInfo.cashList.map((i, index) => {
              this.cashList.map(j => {
                if (i.cashType === j.cashType) {
                  j.surplusAmount = i.surplusAmount
                  j.payAmount = i.payAmount
                  if(j.cashType === 140 && j.surplusAmount === 0) {
                    j.forbid = true
                  }
                  if(i.isPay === 1) {
                    this.currentPayType = i.cashType
                    this.currentPayAmonut = i.payAmount
                  }
                }
              })
            });
            
            if (this.payInfo.status === 100) {
              this.cashList.map(i => i.forbid = true)
            }
            console.log(this.cashList)

            if (res.data.loansList.length > 0) {
              this.showLoanApply = false
              this.loanList = res.data.loansList
              this.loanList.map(i => {
                i.name = this.getLabelByValue(i.loansType, this.bankList)
                i.img = require(`../../assets/images/bank/${i.bankType}.png`)
                if (i.isPay === 1) {
                  this.currentPayType = 100;
                  this.currentLoanPayType = i.loansType
                  this.currentPayAmonut = i.payAmount
                }

                if (i.surplusAmount > 0) {
                  i.firbid = false
                } else {
                  i.forbid = true
                }

                if (i.bankType === 12) {
                  i.remark = '金额小于￥1,000.00时不可用'
                }
                if (i.loansType === 110) {
                  this.loanList[0].surplusAmount = i.surplusAmount
                }
              })
            } else {
              this.showLoanApply = true
              this.loanList = res.data.loansList
            }
            
            this.pageLoading = false;
            if (!this.payTimer) {
              this.payTimer = setInterval(() => {
                this.daojishi();
              }, 1000);
            }
          }

          this.countWillPayAmount();
          this.changePayWayStatus();
        })
      },
      buy_close() {
        this.buy_code = false;

      },
  
      //监听是否支付成功
      wx_time(paySn) {
        if (this.buy_code == false) {
          this.$refs.qrcode.innerHTML = "";
          //关闭弹窗后
          clearInterval(this.timer1);
          return;
        }
        findOrderByPaySn({ paySn: paySn }).then((res) => {
          if (res.code == 200) {
            if (res.data.status == 140) {
              //成功后关闭定时器
              clearInterval(this.timer1);
              this.$refs.qrcode.innerHTML = ""; //清空渲染的二维码
              this.buy_code = false; //关闭弹窗
              this.$message({
                type: "success",
                message: "支付成功",
              });
              this.$router.push("/home");
            }
          } else {
            //成功后关闭定时器
            clearInterval(this.timer1);
            this.$refs.qrcode.innerHTML = ""; //清空渲染的二维码
            this.buy_code = false; //关闭弹窗
          }
        });
      },
      
      //  支付节流
      throttlePay:_.throttle(function(){
        this.payFn()
      },1000),
      payFn() {
        // 支付方式包含慧金豆或账期需填写支付密码
        if ((this.currentHuiIcon.mainSelect || this.currentPayType == 140) && !this.password) {
          this.payDialog = true;
          return;
        } else {
          this.payDialog = false;
        }

        if (!this.currentHuiIcon.mainSelect && !this.currentPayType) {
          this.$message.warning('请选择支付方式!');
          return;
        }

        this.doubleclick = true;
        this.buyLoading = true;

        let info = {
          paymentType: this.currentPayType === 100 ? this.currentLoanPayType : this.currentPayType,
          hjdFlag: this.currentHuiIcon.mainSelect ? 1 : 0,
          gps: this.location,
          paySn: this.paySn,
          pwd:  Base64.encode(this.password),
          wxDevice: 0
        };
        console.log(info);
        
        submitPayInfoAPI(info).then(res => {
          if (res.code === 200) {
            if (res.data.payUrl) {
              this.buy_code = true;
              this.$nextTick(() => {
                this.qrcode = new QRCode(this.$refs.qrcode, {
                  width: 300, //二维码宽度
                  height: 300, //二维码高度
                  text: res.data.payUrl, //调用微信支付接口返回的微信特殊链接：例如"weixin://wxpay/bizpayurl?pr="
                  colorDark: "#000", //颜色配置
                  colorLight: "#fff",
                });
              });
              //循环请求查看是否支付成功
              this.timer1 = setInterval(() => {
                this.wx_time(res.data.paySn);
              }, 1000);
            } else {
              if (res.data.firmCertFlag) {
                this.$message.success('已提交支付，请联系银行创建借款合同')
              } else {
                this.$message.success("支付成功");
              }

              setTimeout(() => {
                this.$router.push("/home");
              }, 1000);
            }
          }
          this.doubleclick = false;
          this.buyLoading = false;
        })
        .catch(() => {
          this.buyLoading = false;
        });
      },
  
      //  取消输入密码
      cancelPay(){
        this.password = ''
        this.payDialog = false
        this.doubleclick = false
      },
      //  关闭二维码弹窗
      closeQrcode(){
        this.buy_code = false
        this.$refs.qrcode.innerHTML = "";
        this.batchOrder();
      },
  
      // 药采贷申请
      applyLoan(){
        this.dialogVisible = true
      },
      closeLoan(value) {
        this.dialogVisible = value
      },

      // 根据支付方式修改默认支付金额
      changeIconAmount() {
        if (!this.currentHuiIcon.mainSelect) {
          this.currentHuiIcon.payAmount = 0;
          this.currentHuiIcon.status = null;
        } else {
          if (this.currentHuiIcon.surplusAmount < this.payInfo.totalAmount) {
            this.currentHuiIcon.payAmount = this.currentHuiIcon.surplusAmount
          } else {
            this.currentHuiIcon.payAmount = this.payInfo.totalAmount
          }
        }
        this.changeCurrentAmount()
      },
      
      
      changeCurrentAmount() {
        const amount = this.payInfo.totalAmount - this.currentHuiIcon.payAmount;
        if (amount > 0) {
          this.currentPayAmonut = amount;
        } else {
          this.currentPayAmonut = 0;
        }
        this.changePayWayStatus();
        this.countWillPayAmount();
      },
      // 支付方式切换
      changeCurrentType(e) {
        this.changeCurrentAmount();
        if (e === 100) {
          for(let i=0;i< this.loanList.length; i++) {
            if (this.currentPayAmonut <= this.loanList[i].surplusAmount) {
              this.currentLoanPayType = this.loanList[i].loansType
              return
            }
          }
        }
      },
      // 药采贷支付方式切换
      changeLoanType(item) {
        if (item.forbid) return;
        this.currentLoanPayType = item.loansType;
      },
      
      // 计算页面应付金额
      countWillPayAmount() {
        this.payInfo.willPayAmount = Number((this.currentHuiIcon.payAmount + this.currentPayAmonut).toFixed(2));
      },

      // 取消子支付单支付
      confirmCancelPay() {
        this.$confirm('是否取消支付？','提示',{
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          const data = {
            loansType: this.currentLoan.loansType,
            payItemSn: this.currentLoan.payItemSn
          }
          cancelPayAPI(data).then(res => {
            if (res.code === 200) {
              this.$message.success(res.data);
              this.currentLoan = {};
              this.batchOrder();
            }
          })
        }).catch(() => {})
      },

      // 动态更改支付方式可选状态
      changePayWayStatus() {
        if (this.payInfo.status !== 100) {
          const total = this.currentPayAmonut.toFixed(2);

          this.loanList.map(i => {
            i.surplusAmount < total ? i.forbid = true : i.forbid = false;
            if (i.bankType === 12 && total < 1000) {
              i.forbid = true
            }
          })

          this.cashList.map(i => {
            i.cashType === 140 && i.surplusAmount < total ? i.forbid = true : i.forbid = false;
          })
        }
      }
    },

    beforeDestroy() {
      clearInterval(this.payTimer);
    }
  };
  </script>
  
  <style lang="less" scoped>
  @import "../../assets/base.less";

  ::v-deep {
    .paydialog {
      .mb8 {
        margin-bottom: 8px;
      }
      .nowarp {
        white-space: nowrap;
      }
      .tip {
        font-size: 12px;
        margin-bottom: 20px;
        color: #707070;
      }
      .el-dialog__header {
        background: #f7f8fa;
      }
    }
    .paylog {
      .el-dialog__header {
        display: none;
      }
      .el-dialog {
        position: relative;
        margin: 0 auto 50px;
        background: none;
        border-radius: 2px;
        box-shadow: none;
        box-sizing: border-box;
      }
    }
  }
  .rule-box {
    ::v-deep {
      .el-dialog__header {
        background: #f7f8fa;
      }
    }
    .rule-tip {
      font-size: 14px;
      font-family: 'regular';
      font-weight: bold;
      color: #333333;
      line-height: 20px;
      margin-bottom: 18px;
    }
    .rule-txt {
      font-size: 14px;
      font-family: 'regular';
      font-weight: 400;
      color: #333333;
      line-height: 20px;
      margin-bottom: 20px;
    }
  }
  
  .fs18 {
    font-size: 16px;
    font-family: 'medium';
    font-weight: 400;
    color: #333333;
  }
  .body {
    padding-top: 17px;
    background-color: @body;
  }
  .footer {
    height: 110px;
    padding-right: 52px;
    text-align: right;
    .info {
      margin-left: 27px;
    }
    .footer-operation {
      margin-top: 26px;
    }
  }
  .top {
    background-image: url(../../assets/images/pay-bg.png);
    box-sizing: border-box;
    height:121px;
    padding: 26px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .tt {
      font-size: 24px;
      font-weight: 400;
      color: #ffffff;
    }
    .time{
      margin-top:16px;
      color:rgba(255,255,255,.85);
    }
    .notice{
        font-size: 14px;
        color: #ffffff;
        font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
        cursor: pointer;
        display: flex;
        align-items: center;
        img {
            width: 14px;
            height: 14px;
            margin-right: 6px;
        }
    }
  }
  
  .bottom {
    padding: 21px 52px;
    border-radius: 2px;
    margin-bottom:20px;
    .list {
      background: #FFFFFF;
      border: 1px solid #EBEBEB;
      position: relative;
      .notice-box{
        height:36px;
        background: #FFF7E8;
        border-radius: 10px 10px 0px 0px;
        border: 1px solid #FFCC9B;
        color: #FF7D00;
        line-height: 36px;
        padding-left:16px;
        display: flex;
        align-items: center;
        img{
          width: 16px;
          height: 16px;
          margin-right:10px;
        }
      }
      .info-box{
        display: flex;
        align-items: center;
        height: 78px;
      }
      .right {
        text-align: right;
        flex-grow: 1;
        .dikou {
          font-size: 14px;
          color: #9e9fab;
        }
      }
    }

    .info-radio {
      display: flex;
      align-items: center;
      padding-left: 22px;
      :deep(.el-radio__inner) {
        width: 16px;
        height: 16px;
      }
    }
    .box {
      display: flex;
      align-items: center;
      padding-left: 17px;
      .box-select {
        width: 24px;
        height: 24px;
        margin-right: 15px;
        text-align: center;
        line-height: 24px;
        :deep(.el-checkbox__inner) {
          width: 16px;
          height: 16px;
          &::after {
            height: 8px;
            left: 4px;
            width: 4px;
          }
        }
      }
      .box-icon {
        width: 36px;
        height: 36px;
        margin-right: 11px;
      }
      .box-name {
        margin-right: 18px;
      }
      .feature-box {
        width: 38px;
        height: 23px;
        border-radius: 6px;
        border: 1px solid #BFBFBF;
        font-size: 12px;
        font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
        font-weight: 400;
        color: #808080;
        text-align: center;
        line-height: 23px;
        cursor: pointer;
        margin-right: 10px;
      }
      .feature-box-green {
        color: #26C487;
        border-color: #26C487;
      }
    }
    .residue {
      width: 400px;
      color: #333333;
      .el-input {
          width: 240px;
          margin: 0 10px;
      }
    }
  }
  .bigred {
    font-size: 24px;
    color: #FF6200;
    font-weight: bold;
    margin-right: 20px;
  }
  
  .loan-apply {
    width: 100%;
    height: 54px;
    background: linear-gradient(90deg, #FBE0B9 0%, #F5B258 100%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    font-size: 14px;
    color: #3C2609;
    .loan-apply-title {
        font-size: 16px;
    }
    div {
      display: flex;
      align-items: center;
    }
    img {
      width: 28px;
      height: 28px;
      margin-right: 12px;
    }
    .button {
      width: 107px;
      height: 44px;
      background: linear-gradient(90deg, #374581 0%, #283158 100%);
      border-radius: 10px;
      font-size: 16px;
      color: #FFFFFF;
      text-align: center;
      line-height: 44px;
      margin-left: 20px;
      cursor: pointer;
    }
  }

.scroll-box {
  border-top: 1px solid #EBEBEB;
  padding: 20px 0 20px 53px;
  max-height: 200px;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}
.pay-item-box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .pay-item {
    width: 384px;
    background: #FFFFFF;
    border-radius: 8px;    
    border: 1px solid #EBEBEB;
    cursor: pointer;
    padding: 12px 10px;
    margin: 0 20px 10px 0;
    display: flex;
    align-items: center;
    position: relative;
    img {
      width: 24px;
      height: 24px;
      margin-right: 4px;
    }
    .limit {
      margin-left: 92px;
    }
  }
  .loan-item {
    flex-wrap: wrap;
    .limit {
      width: 350px;
      margin: 5px 0 0 28px;
    }
    .remark {
      margin-left: 20px;
      color: #999999;
    }
  }
  .checked {
    border-color: #26C487;
    .img-check {
      position: absolute;
      top: -1px;
      right: -1px;
      margin-right: 0;
      width: 30px;
    }
  }
  .forbid {
    background: #EEEFF0;
    cursor: not-allowed;
    opacity: .4;
  }
}
</style>
  