<template>
  <div class="body">
    <div class="banxin">
      <div>
        <div class="fhui">
          应还总金额：
          <span class="fred" style=" font-size: 32px;">
            {{ orderPay.totalAmt }}
          </span>
          <span class="fred">元</span>
        </div>
        <div class="hui-card">
          <img src="../../../assets/images/huijindou.png" />
          <span class="huijindou">慧金豆</span>
          <span class="hui">已自动抵扣</span>
          <span class="gr">￥{{ orderPay.amountAmt }}</span>
        </div>
      </div>
      <div class="paylist">
        <div class="fs18">支付方式</div>
        <div class="ul">
          <div
            v-if="isLoan"
            @click="activeFn(1)"
            :class="active == 1 ? 'li df jfc aic active' : 'li df jfc aic'"
          >
            <img
              style="margin-right: 5px"
              src="@/assets/images/cart/dai.png"
              alt=""
            />
            <span class="fs18">药采贷</span>
            <el-tag>推荐</el-tag>
          </div>
          <div
            class="li df jfc aic"
            :class="active == 3 ? 'active' : ''"
            @click="activeFn(3)"
          >
            <img src="../../../assets/images/cart/zhifubao.png" alt="" />
            <span>支付宝</span>
          </div>
          <div
            class="li df jfc aic"
            :class="active == 4 ? 'active' : ''"
            @click="activeFn(4)"
          >
            <img src="../../../assets/images/cart/weixin.png" alt="" />
            <span>微信</span>
          </div>
        </div>
      </div>
      <div class="footer">
        <el-button @click="$router.push('/user/accountPeriod')">返回</el-button>
        <el-button @click="payFn" type="success">去支付</el-button>
      </div>
    </div>
    <!-- 微信支付弹框 -->
    <div class="paylog">
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="buy_code"
        width="430px"
        @close="buy_close"
        v-loading="buyLoading"
      >
        <img src="@/assets/images/quicklyOrder/payIMG.png" alt="" />
        <div
          style="
              display: flex;
              justify-content: center;
              position: absolute;
              top: 0;
              right: 0;
              transform: translate(-70px, 165px);
            "
          class="buy_box dis_f_c_c"
        >
          <div id="qrcode" ref="qrcode" class="img1 dis_f_c_c"></div>
          <img src="static/image/store/pay2.png" class="img2" alt="" />
        </div>
        <div
          @click="buy_code = false"
          style="margin-top: 40px"
          class="cup flex-center"
        >
          <img src="@/assets/images/quicklyOrder/Close.png" alt="" />
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  repay,
  findDetailByPaySn,
  repayPre,
} from "@/request/Orders/order";
import axios from "axios";
import QRCode from "qrcodejs2"; // 引入qrcode,转二维码
import { info } from "@/request/users/users";
export default {
  watch: {
    $route: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue) {
          if (typeof newValue.query.ids == "string") {
            this.ids = [];
            this.ids.push(newValue.query.ids);
          } else {
            this.ids = newValue.query.ids;
          }
        }
      },
    },
  },
  data() {
    return {
      //用户对公对私权限
      Paycom: false,
      isLoan: localStorage.getItem("isloan") || false,
      ids: [],
      orderPay: {},
      paysuccess: false,
      //gps
      location: "",
      lat: "",
      lng: "",
      buy_code: false,
      active: 1,
      bizCd: "",
      paymentType: 110, //支付方式,
      qrcode: "", // 二维码
      buyLoading: false,
    };
  },
  mounted() {
    axios({
      url: `https://restapi.amap.com/v3/ip?key=d369aeea0b01d0c9a5c4b0fac64127ad`,
    })
      .then((res) => {
        if (res.status == 200) {
          const loc = res.data.rectangle.split(";")[0].split(",");
          this.location =
            (loc && loc[1] ? loc[1] : "30.53006918") +
            "-" +
            (loc && loc[0] ? loc[0] : "103.9017713");
        }
      })
      .catch((error) => {});
  },
  created() {
    if (!this.isLoan) {
      this.active = 3;
      this.bizCd = "0000008";
      this.paymentType = 120;
    }

    this.batchOrder();
    this.getUserInfo();
  },
  methods: {
    //再次获取用户权限信息
    getUserInfo() {
      info().then((res) => {
        if (res.code === 200) {
          sessionStorage.setItem("setOwerId", res.data.id);
          this.$store.commit("setOwerId", res.data.id);
          //药采贷权限
          if (res.data.permissions.indexOf("mall:pay:loan") !== -1) {
            // this.$store.commit("loan", true);
            localStorage.setItem("isloan", "true");
          } else {
            // this.$store.commit("loan", false);
            localStorage.removeItem("isloan");
          }
          //我的积分权限
          if (res.data.permissions.indexOf("mall:pay:integral") !== -1) {
            // this.$store.commit("myPoint", true);
            localStorage.setItem("myPoint", "true");
          } else {
            // this.$store.commit("myPoint", false);
            localStorage.removeItem("myPoint");
          }
          //对公支付
          if (res.data.permissions.indexOf("mall:pay:com") !== -1) {
            // this.$store.commit("myPoint", true);
            localStorage.setItem("Paycom", "true");
            this.Paycom = true;
          } else {
            this.Paycom = false;
            // this.$store.commit("myPoint", false);
            localStorage.removeItem("Paycom");
          }
        }
      });
    },

    batchOrder() {
      repayPre(this.ids).then((res) => {
        if (res.code === 200) {
          this.orderPay = res.data;
        }
      });
    },
    buy_close() {
      this.buy_code = false;
    },

    //监听是否支付成功
    wx_time(paySn) {
      if (this.buy_code == false) {
        this.$refs.qrcode.innerHTML = "";
        //关闭弹窗后
        clearInterval(this.timer1);

        return;
      }
      findDetailByPaySn({ paySn: paySn }).then((res) => {
        if (res.code == 200) {
          if (res.data.status == 140) {
            this.paysuccess = true;
            //监听支付成功后
            //成功后关闭定时器
            clearInterval(this.timer1);
            this.$refs.qrcode.innerHTML = ""; //清空渲染的二维码
            this.buy_code = false; //关闭弹窗
            this.$message({
              type: "success",
              message: "支付成功",
            });
            this.$router.push("/home");
          }
        } else {
          //成功后关闭定时器
          clearInterval(this.timer1);
          this.$refs.qrcode.innerHTML = ""; //清空渲染的二维码
          this.buy_code = false; //关闭弹窗
        }
      });
    },
    activeFn(index) {
      this.active = index;
      switch (index) {
        case 1:
          this.bizCd = "";
          this.paymentType = 110;
          break;
        case 2:
          this.bizCd = "";
          this.paymentType = 100;
          break;
        case 3:
          this.bizCd = "0000008";
          this.paymentType = 120;
          break;
        case 4:
          this.bizCd = "0000007";
          this.paymentType = 120;
          break;

        default:
          break;
      }
    },
    payFn() {
          this.buyLoading = true;

          //       {
          //   "bizCd": "string",线上现金 支付方式 支付宝编码 0000008 微信 0000007
          //   "createOdr": 0,是否生成 支付参数 只在现金支付 有效 0生成 1不生成
          //   "gps": "string",//经纬度
          //   "odrIds": [0] //订单编号
          //   "paySn": "string",支付号(长度限制为0-60个字符)
          //   "paymentType": 0,支付方式：账期 100 药采贷 110 线上支付120
          // }
          let info = {
            entFlag: this.Paycom ? 1 : 0, //对私0对公1
            bizCd: this.bizCd,
            gps: this.location,
            odrIds: this.ids,
            paymentType: this.paymentType,
            createOdr: 0,
          };
          repay(info)
            .then((res) => {
              if (
                res.code == 200 &&
                this.paymentType == 110 &&
                localStorage.getItem("Paycom")
              ) {
                this.$message.success("已提交对公支付");
                return;
              }

              if (res.data.creditFullPay == 0 && res.code == 200) {
                if (res.data.payUrl) {
                  this.buy_code = true;
                  this.$nextTick(() => {
                    this.qrcode = new QRCode(this.$refs.qrcode, {
                      width: 300, //二维码宽度
                      height: 300, //二维码高度
                      text: res.data.payUrl, //调用微信支付接口返回的微信特殊链接：例如"weixin://wxpay/bizpayurl?pr="
                      colorDark: "#000", //颜色配置
                      colorLight: "#fff",
                    });
                  });
                }
                //循环请求查看是否支付成功
                this.timer1 = setInterval(() => {
                  this.wx_time(res.data.paySn);
                }, 1000);

                //使用nextTick确保在弹框显示前二维码数据渲染，不加的话可能报错获取不到qrcode元素
              } else if (res.data.firmCertType == 1) {
                this.$message.success("正在支付中");
                setTimeout(() => {
                  this.$router.push("/home");
                }, 1000);
              } else if (res.data.creditFullPay == 1) {
                this.$message.success("支付成功");
                setTimeout(() => {
                  this.$router.push("/home");
                }, 1000);
              } else if (res.code !== 200) {
                this.buy_code = false;
              }
              this.buyLoading = false;
            })
            .catch(() => {
              this.buyLoading = false;
            });
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../../assets/base.less";
::v-deep .tableHeader th {
  background: #f7f8fa !important;
  margin-bottom: 40px;
}
::v-deep .stocklog .el-dialog__body {
  padding: 30px;
}
::v-deep .stocklog .el-dialog__header {
  background: #f7f8fa !important;
  font-size: 20px;
  height: 60px;
  font-family: 'medium';
  font-weight: 600;
  color: #202d40;
}
::v-deep {
  .paylog {
    .el-dialog__header {
      display: none;
    }
    .el-dialog {
      position: relative;
      margin: 0 auto 50px;
      background: none;
      border-radius: 2px;
      box-shadow: none;
      box-sizing: border-box;
    }
  }
  .el-tag {
    color: #fff;
    margin-left: 10px;
    background-color: #ff6700;
    border-color: #ff6700;
    width: 32px;
    height: 22px;
    padding: 0;
    line-height: 22px;
    font-size: 12px;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    box-sizing: border-box;
    white-space: nowrap;
  }
}
.hui-card {
  margin-top: 40px;
  width: 600px;
  padding: 18px 22px;
  border: 1px solid #f5f5f5;
  display: flex;
  align-items: center;
  .huijindou {
    color: #333;
    font-size: 18px;
    margin-right: 10px;
  }
  .hui {
    color: #999999;
  }
  img {
    margin-right: 7px;
    width: 28px;
    height: 28px;
  }
}

.fs18 {
  width: 72px;
  font-size: 18px;
  font-family: 'medium';
  font-weight: 500;
  color: #333333;
}
.body {
  padding: 54px 33px 0;
  background-color: #fff;
}
.footer {
  height: 113px;
  border-top: 1px solid @hui;
  background: #fff;
  align-items: center;
  padding-right: 40px;
  display: flex;
  justify-content: flex-end;
}
.paylist {
  margin-top: 30px;
  height: 240px;
  box-sizing: border-box;
  padding: 44px;
  background: #ffffff;
  .ul {
    display: flex;
    margin-top: 34px;
    font-size: 20px;
    font-weight: 600;
    .li {
      cursor: pointer;
      text-align: center;
      width: 200px;
      line-height: 70px;
      height: 70px;
      border: 1px solid @hui;
      margin-right: 34px;
    }
    .active {
      border: 1px solid #26c487;
    }
  }
}
</style>
