import request from '../request'

// export const  getInitData=(params)=> {
//     return request({
//         url: `/tssinquestion/list`,
//         method: 'GET',
//         params: params
//     })
// }
// export const  LoginApi=(data) =>{
//     return request({
//         url: `/mall/auth/login`,
//         method: 'POST',
//         data: data
//     })
// }
// export const  editTssinquestion=(data)=> {
//     return request({
//         url: `/tssinquestion`,
//         method: 'PUT',
//         data: data
//     })
// }
// export const deleteTssinquestion=(ids)=> {
//     return request({
//         url: `/tssinquestion/delete`,
//         method: 'DELETE',
//         data: ids
//     })
// }
// 上传文件api
export function uploadAPI(data) {
    return request({
        url: '/oss/admin/sys/oss/upload',
        method: 'post',
        data
    })
}
// 开始对码
export function otrherCodeAPI(data) {
    return request({
        url: '/other-code/mall/other/order/addOrder',
        method: 'post',
        data
    })
}
// 一键加入购物车
export function oneKeyShopAPI(data) {
    return request({
        url: '/other-code/mall/other/order/oneKeyShop',
        method: 'post',
        data
    })
}
// 对码文件商品
export function productListAPI(data) {
    return request({
        url: '/other-code/mall/other/order/productList',
        method: 'post',
        data
    })
}

export function orderListAPI(data) {
    return request({
        url: '/other-code/mall/other/order/orderList',
        method: 'post',
        data
    })
}





///////////////
//取消订单(待付款)
export const orderCancel = (params) => request.put(`/order-v2/mall/order/orderCancel/${params.orderId}`);
// 取消订单（支付中）
export function orderCancelAPI(data) {
    return request({
        url: '/order-v2/admin/order/order/cancelOrder',
        method: 'post',
        data
    })
}
//确认收货
export const productReceived = (params) => request.put(`/order-v2/mall/order/productReceived/${params.orderId}`);
// 批量确认收货
export function groupProductReceived(data) {
    return request({
        url: '/order-v2/mall/order/productBatchReceived',
        method: 'post',
        data
    })
}
//查询订单
export const findOrderByPaySn = (params) => {
    return request({
        url: `/order-v2/mall/order/findOrderByPaySn`,
        method: 'GET',
        params: params
    })
}
//查询账期还款
export const findDetailByPaySn = (params) => {
    return request({
        url: `/pay-v2/admin/termsDetailController/findDetailByPaySn`,
        method: 'GET',
        params: params
    })
}
//快速下单商城列表
export const getCorrectCodeProductsList = (params) => {
    return request({
        url: `product-v2/mall/products/list`,
        method: 'GET',
        params: params
    })
}

// 手动对码
export const orderManualAPI = (data) => {
    return request({
        url: `/other-code/mall/other/order/manual`,
        method: 'POST',
        data: data
    })
}
// 账期待还款个数
export const signRepayment = (params) => {
    return request({
        url: `pay-v2/pay/termsDetailController/signRepayment`,
        method: 'GET',
        params: params
    })
}
// 账期列表
export const termsDetailController = (data) => {
    return request({
        url: `/pay-v2/pay/termsDetailController/page`,
        method: 'POST',
        data: data
    })
}
// 账期列表详情
export const getOrderDetailByPaySn = (data) => {
    return request({
        url: `/order-v2/mall/order/getOrderDetailByPaySn`,
        method: 'POST',
        data: data
    })
}

// 手动对码
export const reNumAPI = (data) => {
    return request({
        url: `/other-code/mall/other/order/reNum`,
        method: 'POST',
        data: data
    })
}
// 手动对码全部选择
export const checkAllAPI = (data) => {
    return request({
        url: `/other-code/mall/other/order/checkAll`,
        method: 'POST',
        data: data
    })
}
// 手动对码批量选择
export const checkIds = (data) => {
    return request({
        url: `/other-code/mall/other/order/checkIds`,
        method: 'POST',
        data: data
    })
}
// 手动对码选择单个
export const checkOneAPI = (data) => {
    return request({
        url: `/other-code/mall/other/order/checkOne`,
        method: 'POST',
        data: data
    })
}
//申请售后详情
export const applyRefundDetail = (params) => {
    return request({
        url: `/order-v2/mall/refund/applyRefundDetail`,
        method: 'GET',
        params: params
    })
}

// export const  getLocation=(params)=> {
//     return request({
//         url: `https://restapi.amap.com/v3/ip?key=d369aeea0b01d0c9a5c4b0fac64127ad`,
//         method: 'GET',
//         params: params
//     })
// }


// 审批撤销接口
export const mallEditApprovalStatus = (data) => {
    return request({
        url: `/member-v2/aide/myApproval/mallEditApprovalStatus`,
        method: 'POST',
        data: data
    })
}
// 订单列表
export const orderList = (data) => {
    return request({
        url: `/order-v2/mall/order/orderList`,
        method: 'POST',
        data: data
    })
}
// 订单列表
export const mallPurchasePlanDetail = (data) => {
    return request({
        url: `/member-v2/aide/myApproval/mallPurchasePlanDetail`,
        method: 'POST',
        data: data
    })
}
// 积分列表
export const mallCreditsDetailList = (data) => {
    return request({
        url: `/pay-v2/mallCreditsDetail/mallCreditsDetailList`,
        method: 'POST',
        data: data
    })
}
//总积分查询
export const mallCreditStatistical = (params) => {
    return request({
        url: `/pay-v2/mallCredits/mallCreditStatistical`,
        method: 'GET',
        params: params
    })
}
// 快速下单历史记录列表
export const goodsHeaderPage = (data) => {
    return request({
        url: `/other-code/mall/goodsHeader/page`,
        method: 'POST',
        data: data
    })
}
// 快速下单导出当前订单
export const downLoadGoodBase = (data) => {
    return request({
        url: `/other-code/mall/goodsHeader/downLoadGoodBase`,
        method: 'POST',
        data: data,
        responseType: "blob",
    })
}
// 快速下单导出当前订单
export const goodsHeaderInfo = (data) => {
    return request({
        url: `/other-code/mall/goodsHeader/info`,
        method: 'POST',
        data: data,
    })
}
// 删除对码商品
export const del = (data) => {
    return request({
        url: `/other-code/mall/goodsBase/del`,
        method: 'POST',
        data: data,
    })
}
// 快速下单确认转码
export const uploadCertain = (data) => {
    return request({
        url: `/other-code/mall/excel/uploadCertain`,
        method: 'POST',
        data: data
    })
}
// 快速下单人工确认excel
export const manMade = (data) => {
    return request({
        url: `/other-code/mall/goodsBase/manMade`,
        method: 'POST',
        data: data
    })
}
// 快速下单人工取消excel
export const cancelExcel = (data) => {
    return request({
        url: `/other-code/mall/goodsBase/cancel`,
        method: 'POST',
        data: data
    })
}
// 快速右侧列表
export const goodsBasePage = (data) => {
    return request({
        url: `/other-code/mall/goodsBase/page`,
        method: 'POST',
        data: data
    })
}
// 确认寄回
export const returnProduct = (data) => {
    return request({
        url: `/order-v2/mall/refund/returnProduct`,
        method: 'POST',
        data: data
    })
}
// 批量提交订单
export const addBatchOrder = (data) => {
    return request({
        url: `/order-v2/mall/order/addBatchOrder`,
        method: 'POST',
        data: data
    })
}
// 账期还款
export const repay = (data) => {
    return request({
        url: `/pay-v2/pay/termsController/repay`,
        method: 'POST',
        data: data
    })
}
// 提交账期还款
export const repayPre = (data) => {
    return request({
        url: `/pay-v2/pay/termsController/repayPre`,
        method: 'POST',
        data: data
    })
}
// 退货申请
export const submitCheck = (data) => {
    return request({
        url: `/order-v2/mall/refund/submitCheck`,
        method: 'POST',
        data: data
    })
}

// 上传提货委托书
export const updateReceiveEntrust = (data) => {
    return request({
        url: `/mall/order/updateReceiveEntrust`,
        method: 'POST',
        data: data
    })
}
// 申请售后列表
export const applyRefundList = (data) => {
    return request({
        url: `/order-v2/mall/refund/applyRefundList`,
        method: 'POST',
        data: data
    })
}
// 支付接口
export const addPayOrder = (data) => {
    return request({
        url: `/order-v2/mall/order/orderPay`,
        method: 'POST',
        data: data
    })
}
// 审批详情
export const myApprovalDetail = (params) => {
    return request({
        url: `/member-v2/aide/myApproval/shoppingMall`,
        method: 'GET',
        params: params
    })
}
// 订单详情
export const orderDetail = (data) => {
    return request({
        url: `/order-v2/mall/order/orderDetail`,
        method: 'POST',
        data: data
    })
}
// 订单列表数量
export const orderCount = (customerAddrId) => {
    return request({
        url: `/order-v2/mall/order/orderCount/${customerAddrId }`,
        method: 'POST',
    })
}
// 审批列表数量
export const findMallApprovalNum = (customerAddrId) => {
    return request({
        url: `/member-v2/aide/myApproval/findMallApprovalNum`,
        method: 'GET',
    })
}
export const addOrder = (data) => {
    return request({
        url: `/order-v2/mall/order/addOrder`,
        method: 'POST',
        data: data
    })
}
// 订单库存校验
export const orderStockValid = (data) => {
    return request({
        url: `/order-v2/mall/order/orderStockValid`,
        method: 'POST',
        data: data
    })
}
//库存校验修改订单
export const updateOrder = (data) => {
    return request({
        url: `/order-v2/mall/order/update`,
        method: 'PUT',
        data: data
    })
}

export const orderConfList = (data) => {
    return request({
        url: `/order-v2/mall/order/orderConf`,
        method: 'POST',
        data: data
    })
}

//  获取售后单可退商品列表
export const getRefundableProduct = (data) => {
    return request({
        url: `/order-v2/mall/refund/refundableProduct`,
        method: 'POST',
        data: data
    })
}

//  存储中间表售后数据
export const saveSelectRefundProduct = (data) => {
    return request({
        url: `/order-v2/admin/middle/insert`,
        method: 'POST',
        data: data
    })
}

//  查询中间表售后数据
export const getSelectRefundProduct = (data) => {
    return request({
        url: `/order-v2/admin/middle/list`,
        method: 'POST',
        data: data
    })
}

//  删除中间表售后数据(单个与批量)
export const removeSelectRefundProduct = (data) => {
    return request({
        url: `/order-v2/admin/middle/delete`,
        method: 'POST',
        data: data
    })
}

//  删除全部中间表售后数据
export const removeAllSelectRefundProduct = (params) => {
    return request({
        url: `/order-v2/admin/middle/deleteAll`,
        method: 'GET',
        params
    })
}

//  调整中间表数据
export const updateSelectRefundProduct = (data) => {
    return request({
        url: `/order-v2/admin/middle/update`,
        method: 'POST',
        data: data
    })
}

//  获取中间表商品统计数量
export const getRefundProductTotal = (data) => {
    return request({
        url: `/order-v2/admin/middle/listStatistics`,
        method: 'POST',
        data: data
    })
}

//  编辑订单备注信息
export const editRemarkAPI = (data) => {
    return request({
        url: `/order-v2/mall/order/editRemark`,
        method: 'POST',
        data: data
    })
}

// 根据订单获取paySn (新版支付)
export const getPaysnByOrderIds = (data) => {
    return request({
        url: `/order-v2/mall/order/submitOrder`,
        method: 'POST',
        data: data
    })
}

// 根据paySn获取当前支付单信息（新版支付）
export const getPayInfoBySn = (sn) => {
    return request({
        url: `/pay-v2/mall/pay/paymentPage?paySn=${sn}`,
        method: 'GET',
    })
}

// 提交支付信息 (新版支付)
export const submitPayInfoAPI = (data) => {
    return request({
        url: `/pay-v2/mall/pay/confirmPayment`,
        method: 'POST',
        data: data
    })
}
// 取消支付明细 (新版支付)
export const cancelPayAPI = (data) => {
    return request({
        url: `/pay-v2/mall/pay/cancelPayItem`,
        method: 'POST',
        data: data
    })
}

// 慧金采商城再来一单
export const copyOrderAPI = (orderId) => {
    return request({
        url: `/order-v2/mall/cart/oneMoreOrder?orderId=${orderId}`,
        method: 'GET',
    })
}