/*
 * @Description: 
 * @version: 
 * @Author: chentianxiang
 * @Date: 2023-10-16 11:33:56
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-12-25 09:45:27
 */
//  货币格式化
export function formatMoney(money, symbol) {
    symbol = symbol // 默认是RMB
    money = money || 0
    let str = money.toFixed(2)  // 只取2位小数
    let l = str.split('.')[0] // 获取整数位
    let r = str.split('.')[1] // 获取小数位
    let arr = [] // 用于保存结果

    let len =Math.ceil(l.length / 3) // 3位数一个 `,`
    for (let i = 0 ; i < len; i ++) {
        arr.unshift(l.slice(-3*(i + 1), -3*i || undefined)) // 如果传(-3,0)获取不到参数，将0换成undefined相当于没传
        if (i !== len - 1) { // 最后一次截取不加 `,`了
            arr.unshift(',')
        }
    }
    return symbol + arr.join('') + '.' + r
}

//  正数校验
export function numberRegFun(value){
    let pattern = /^((0{1}\.\d{1,2})|([1-9]\d*\.{1}\d{1,2})|([1-9]+\d*)|0)$/gi  // 匹配正数
    return pattern.test(value)
}

// 根据字典value获取字典label
export function getLabelByValue(value,arr) {
    let label;
    arr.map(i => {
        if (i.value == value) {
            label = i.label;
        }
    })
    return label || '--'
}
